/*!
*
* Template: ChatUp - Responsive Bootstrap 5 Admin Dashboard Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for responsive Template.
*
*/

@import "./custom/helper/fonts";

// Configuration
@import "./bootstrap/functions";
@import "./custom/variables";
@import "./custom/variables/index";
@import "./bootstrap/variables";
@import "./bootstrap/maps";
@import "./bootstrap/mixins";
@import "./bootstrap/utilities";

// Custom Mixin And Helper
@import "./custom/helper/functions";
@import "./custom/helper/mixins";

// Layout & components
@import "./custom/helper/root";
@import "./bootstrap/root";
@import "./bootstrap/reboot";
@import "./bootstrap/type";
@import "./bootstrap/images";
@import "./bootstrap/containers";
@import "./bootstrap/grid";
@import "./bootstrap/tables";
@import "./bootstrap/forms";
@import "./bootstrap/buttons";
@import "./bootstrap/transitions";
@import "./bootstrap/dropdown";
@import "./bootstrap/button-group";
@import "./bootstrap/nav";
@import "./bootstrap/navbar";
@import "./bootstrap/card";
@import "./bootstrap/accordion";
@import "./bootstrap/breadcrumb";
@import "./bootstrap/pagination";
@import "./bootstrap/badge";
@import "./bootstrap/alert";
@import "./bootstrap/progress";
@import "./bootstrap/list-group";
@import "./bootstrap/close";
@import "./bootstrap/toasts";
@import "./bootstrap/modal";
@import "./bootstrap/tooltip";
@import "./bootstrap/popover";
@import "./bootstrap/carousel";
@import "./bootstrap/spinners";
@import "./bootstrap/offcanvas";

// Helpers
@import "./bootstrap/helpers";

// Utilities
@import "./bootstrap/utilities/api";

// // Extra Components and Utilities and plugins
@import "./custom/variables/icon-size";
@import "./custom/components/components";
@import "./custom/helper/utilities";
@import "./custom/plugins/plugins";

@import "./custom/mode/dark/index";
@import "./custom/mode/rtl/index";





