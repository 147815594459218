// navbar
@import "./navbar/top-navbar";
@import "./navbar/navbar";
@import "./navbar/nav-tabs";

// Custom Card identify by card
@import "./card/card";

// sidebar by card
@import "./sidebar/right-sidebar";

// page-content
@import "./page-content/page-content";

//auth
@import "./auth/authentication";

// alert
@import "./alert/alert";

// feed
@import "./feed/feed";

// form-control
@import "./form/form-control-alternative";
@import "./form/form-wizard";
@import "./form/form-wizard-validate";
@import "./form/form-wizard-vertical";
@import "./form/custom-form-check";

// dropdown
@import "./dropdown/dropdown";

// modal
@import "./modal/modal";

// ratio
@import "./ratio/ratio";

//loader
@import "./loader/loader";

// grid
@import "./grid/grid";
//market
@import "./market/market";
//profiles
@import "./profiles/profiles";

// profile
@import "./profile/profile";
@import "./profile/profile-edit";
@import "./profile/profile2";

// group
@import "./group/group";

// Icons
@import "./icons/icons";

// footer
@import "./footer/footer";

// badges
@import "./badge/badge";

// table
@import "./table/table";

// chat
@import "./chat/chat";
@import "./chat/conversation";
@import "./chat/day-section";
@import "./chat/main-content";
@import "./chat/sidebar";

// todo
@import "./todo/todo";

// blog
@import "./blog/blog";

// store
@import "./store/store";
@import "./store/checkout";

//email
@import "./email/email";

// timeline
@import "./timeline/timeline";

//faq
@import "./faq/faq";

//calender
@import "./calender/calender";

//countdown
@import "./countdown/countdown";

// Buttons
@import "./button/bs-btn";
@import "./button/soft-btn";
@import "./button/icon-btn";
@import "./button/fixed-btn";
@import "./button/border-btn";
@import "./floating/floating-btn";

// weather

@import "./weather/weather";

//maintanance
@import "./maintenance/maintenance.scss";

//game
@import "./game/game";

//ui kit
@import "./ui-kit/ui-kit";

//menu style
@import "./menu-style/index";

//chart
@import "./charts/admin";

.chat-message {
    position: relative;
    display: inline-block; /* Ensures that the child elements stay in line */
  }

  .chat-message .dropAbcd {
    display: none; /* Initially hide the dropdown menu */
    position: absolute;
    right: 6px;
    top: 0px;
    z-index: 1000; /* Ensure dropdown is above other content */
  }

  .chat-message:hover .dropAbcd {
    display: block; /* Show the dropdown menu when hovering over .chat-message */
  }

  .iconSize20{
    font-size: 20px !important;

  }
  .iconSize30{
    font-size: 30px !important;
  }

  .WebScroller{
    overflow:auto !important ;
    max-height:80vh !important
  }
  .WebScroller90{
    overflow:auto !important ;
    max-height:90vh !important
  }
  .empty20{
    height: 20px;
  }